<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>
              Pending Vacation Requests
            </h1>
          </div>
          <div class="pa-5 d-flex">
            <v-row no-gutters>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="6 d-flex mt-auto mb-auto">
                    <v-select v-model="action" class="mr-5 action-select" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0" label="Actions" return-object outlined dense />
                  </v-col>
                  <v-col cols="6 d-flex mt-auto mb-auto">
                    <div class="submit-button-container">
                      <Button :label="'Submit'"
                              :btnType="'Submit'"
                              @onClick="approveBulkSelection"
                              :isLoading="loading"
                              class="" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <!-- <DataTable :headers="headers" :multiSelect="true" :items="pendingLeave" :approve="approve" :approveBulk="approveBulk" :rejectFunc="reject" /> -->
          <v-text-field v-model="searchPersonnel"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details>
          </v-text-field>
          <v-data-table loading-text="Loading data. Please wait...."
                        :loading="loading"
                        :headers='headers'
                        :search="searchPersonnel"
                        :items="pendingLeave"
                        show-select
                        :single-select="false"
                        v-model="selected"
                        item-key="guid">
            <template v-slot:item.dates="{ item }">
              <div class="d-flex">
                <v-icon medium @click="viewDateModal(item)">mdi-calendar</v-icon>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2" />
                <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2" />
                <Button :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>

              </div>
            </template>
          </v-data-table>

          <!-- reject dialog  -->
          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField v-model="formData.comments" :label="'Comment'" />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Reject'"
                        :btnType="'Submit'"
                        @onClick="reject"
                        :disabled="!valid"
                        class="mr-4" />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- view dates modal   -->
          <v-dialog v-model="dateDialog" persistent max-width="700">
            <v-card>
              <v-card-title class="headline text-center"><h3>Vacation Days</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-data-table :headers='leaveDaysHeaders'
                              :items="leaveDays">
                </v-data-table>
              </v-card-text>
              <!-- Logs-->
              <v-row>
                <v-card width="700" elevation="0">
                  <v-tabs v-model="tab">
                    <v-tab href="#tab-2">Log</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-2">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular indeterminate
                                               color="primary"></v-progress-circular>
                        </div>
                        <v-data-table v-else
                                      :headers='logHeaders'
                                      :items="logs">
                          <template v-slot:item.activityDate="{ item }">
                            <span>{{ item.activityDate | formatDate }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-row>
              <!--End Logs-->
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="dateDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
        </v-container>
      </div>
       <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>
      <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="confDialog" :btnTitle="'Ok'" @close="confDialog = false" @btnAction="approve"/>
    </div>
  </v-app>
</template>
<script>

import {
  APPROVE_BULK_LEAVE,
  FETCH_PENDING_LEAVE,
  FORWARD_REQUEST
} from '@/store/action-type'

import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import moment from 'moment'
import { requestService } from '@/services'

export default {
  components: {
    Button,
    TextField,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      searchPersonnel: '',
      valid: false,
      dateDialog: false,
      alertType: '',
      confDialog: false,
      rejectDialog: false,
      action: '',
      loading: false,
      loadingLogs: false,
      item: '',
      isLoading: false,
      bulk: false,
      selected: [],
      personnelSearch: false,
      DelegateCAI: null,
      DelegateName: null,
      DelegateEmail: null,
      forwarding: false,
      formData: {
        comments: '',
        id: ''
      },
      leaveDays: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      headers: [
        {
          text: 'Full Name',
          value: 'employeeName'
        },
        {
          text: 'Company Name',
          value: 'companyName'
        },
        {
          text: 'Request Type',
          value: 'requestType'
        },
        {
          text: 'Date',
          value: 'dates'
        },
        {
          text: 'Total vacation days',
          value: 'requestCount'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      leaveDaysHeaders: [
        {
          text: 'Day',
          value: 'Day'
        },
        {
          text: 'Date',
          value: 'date'
        }
      ],
      approvers: [],
      logs: [],
      tab: null,
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comment',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }

      ]
    }
  },
  computed: {
    pendingLeave () {
      return this.$store.getters.pendingLeave
    },
    user () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    approve () {
      const data = [{
        'GUID': this.item.guid,
        'Status': 1,
        'Level': 0,
        'Comments': ''
      }]
      this.isLoading = true
      this.$store
        .dispatch(APPROVE_BULK_LEAVE, data)
        .then((result) => {
          this.showAlertMessage('Approve Request successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approve Request failed', 'error')
        }).finally(() => {
          this.isLoading = false
          this.confDialog = false
        })
    },
    openRejectModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    approveBulkSelection () {
      this.loading = true
      if (this.action.id === 2 && this.formData.comments === '') {
        this.rejectDialog = true
        this.bulk = true
        return
      }

      let bulkItems = []

      this.selected.forEach(element => {
        bulkItems.push({ 'GUID': element.guid, Status: this.action.id, Comments: this.action.id === 1 ? '' : this.formData.comments, Level: 0 })
      })

      this.rejectDialog = false

      this.$store.dispatch(APPROVE_BULK_LEAVE, bulkItems).then((result) => {
        this.showAlertMessage(`${this.action.name}  Requests successful`, 'success')
        // eslint-disable-next-line handle-callback-err
      })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage(`${this.action.name}  Requests failed`, 'error')
        }).finally(() => {
          bulkItems = []
          this.formData.comments = ''
          this.selected = []
          this.loading = false
        })
    },
    viewDateModal (item) {
      console.log('Items', item)
      this.dateDialog = true
      console.log('Items', item)

      const re = /\s*(?:,|$)\s*/
      const dateArr = item.dates.split(re)

      const dateStructure = []

      dateArr.forEach(date => dateStructure.push({ Day: moment(new Date(date)).format('dddd'), date: date }))
      dateStructure.pop()
      this.leaveDays = dateStructure.reverse()

      this.loadingLogs = true
      requestService.leaveApprovalLogs(item.guid).then(({ data }) => {
        // this.approvers = data.currenApprover

        this.logs = data.logs === null ? [] : data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })
    },
    openConfiModal (item) {
      this.item = item
      this.confDialog = true
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    reject () {
      if (this.bulk === true) {
        this.approveBulkSelection()
        return
      }
      const data = [{
        'GUID': this.item.guid,
        'Status': 2,
        'Level': 0,
        'Comments': ''
      }]
      this.$store
        .dispatch(APPROVE_BULK_LEAVE, data)
        .then((result) => {
          this.showAlertMessage('Reject Leave successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Reject Leave failed', 'error')
        }).finally(() => {
          this.formData.comments = ''
          this.rejectDialog = false
        })
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name
      this.DelegateCAI = item.id
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        ItemGuid: this.item.guid,
        // "ItemId":428,//This should be the Employee Id
        'DelegateCAI': this.DelegateCAI,
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail
      }

      this.$store.dispatch(FORWARD_REQUEST, { data, type: 'Leave' }).then((res) => {
        this.showAlertMessage('Forwarded successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false
        this.DelegateCAI = null
        this.DelegateName = null
        this.DelegateEmail = null
      })

      console.log('forward data', data)
    },
    getPendingLeaveApproval () {
      this.loading = true
      this.$store
        .dispatch(FETCH_PENDING_LEAVE)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            this.showAlertMessage('There are no items pending your approval', 'warning')
            this.loading = false
          }
        })
    }
  },
  mounted () {
    this.getPendingLeaveApproval()
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.action-select {
 padding-top: 24px;
}
</style>
